*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    background: #e2dedb;
}

.homeBg {
    background-image:
        linear-gradient(to bottom, rgba(48, 48, 48, 0.52), rgba(90, 90, 90, 0.73)),
        url('../assets/bg.jpg');
    height: 100vh;
    background-size: cover;
    align-items: center;
    justify-content: center;
}

.homeLogo {
    width: 170px;
    height: 90px;
}

.aboutUsBox {
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(-45deg, #b5b44a, #ccb131, #649D66, #06623B);
    animation: gradient 9s ease infinite;
    background-size: 400% 400%;
}

.productsBox {
    height: 75vh;
}

.internalAboutBox {
    margin-top: 25px;
    margin-bottom: 25px;
    flex-direction: row;
    justify-content: center;
}

.aboutUsBox>.internalAboutBox>.part1 {
    padding-left: 80px;
    padding-right: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.aboutUsBox>.internalAboutBox>.part1>.part1Container>.headerText {
    padding-bottom: 50px;
}

.aboutUsBox>.internalAboutBox>.part1>.part1Container>.aboutText {
    padding-top: 25px;
    padding-bottom: 20px;
}

.aboutUsBox>.internalAboutBox>.part2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.panelsAbout {
    min-width: 48vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.panelAbout>p {
    font-size: xx-large;
}

.panelAbout {
    background: #6B0F9C;
    box-shadow: inset 0 0 0 5px rgba(255, 255, 255, 0.1);
    color: white;
    text-align: center;
    align-items: center;
    transition:
        font-size 0.7s cubic-bezier(0.61, -0.19, 0.7, -0.11),
        flex 0.7s cubic-bezier(0.61, -0.19, 0.7, -0.11),
        background 0.2s;
    font-size: 20px;
    background-size: cover;
    background-position: center;
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    cursor: inherit;
    min-height: 18vh;
}

.panel1About {
    background-image:
        url('../assets/aboutusimages/img1.jpg');
}

.panel2About {
    background-image:
        url('../assets/aboutusimages/img2.jpg');
}

.panel3About {
    background-image:
        url('../assets/aboutusimages/img3.jpg');
}

.awardsBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.awardImg {
    width: 100px;
    height: 95px;
}

.fda {
    width: 185px;
}

.halal {
    width: 250px;
}

.prodImg {
    width: 900px;
}

.teamBox {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    background-color: #2D2D2D;
    align-items: center;
}

.teamContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 50px;
    position: relative;
}

.a-box {
    display: inline-block;
    width: 240px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
}

.img-container {
    height: 230px;
    width: 200px;
    overflow: hidden;
    border-radius: 0px 0px 20px 20px;
    display: inline-block;
}

.img-container img {
    height: 250px;
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
}

.inner-skew {
    display: inline-block;
    border-radius: 20px;
    overflow: hidden;
    padding: 0px;
    font-size: 0px;
    margin: 30px 0px 0px 0px;
    background: #c8c2c2;
    height: 250px;
    width: 200px;
}

.text-container {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    padding: 120px 20px 20px 20px;
    border-radius: 20px;
    background: #fff;
    margin: -120px 0px 0px 0px;
    line-height: 19px;
    font-size: 14px;
}

.text-container h2 {
    margin: 20px 0px 10px 0px;
    color: #1b1b1b;
    font-size: 18px;
}

.teamPart1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.teamCardBox {
    display: none;
    flex-direction: column;
}

.contactBox {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #e2dedb;
}

.contactForm {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 50px;
}

.contactPaper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.messageField {
    width: 300px;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@media only screen and (max-width: 1405px) {
    .teamContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        padding: 25px;
    }

}

@media only screen and (max-width: 1405px) {
    .teamContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        padding: 0px;
    }
}

@media only screen and (max-width: 1301px) {
    .a-box {
        display: inline-block;
        width: 210px;
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
    }

    .awardsBox {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .awardImg {
        width: 50px;
        height: 55px;
    }

    .fda {
        width: 100px;
    }

    .halal {
        width: 145px;
    }
}

@media only screen and (max-width: 1151px) {
    .a-box {
        display: inline-block;
        width: 204px;
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
    }

    .img-container {
        height: 189px;
        width: 183px;
        overflow: hidden;
        border-radius: 100px 100px 100px 100px;
        display: inline-block;
        margin-top: 25px;
    }

    .inner-skew {
        display: inline-block;
        border-radius: 20px;
        overflow: hidden;
        padding: 0px;
        font-size: 0px;
        margin: -3px 0px 0px 0px;
        background: #c8c2c2;
        height: 250px;
        width: 200px;
    }
}


@media only screen and (max-width: 1121px) {
    .teamBox {
        display: flex;
        height: 100vh;
        flex-direction: column;
        background-color: #2D2D2D;
        align-items: center;
        justify-content: flex-start;
    }

    .card1, .card2, .card3, .card4, .card5 {
        position: absolute;
    }

    .card1 {
        top: -55px;
        right: 15px;
    }

    .card2 {
        top: -55px;
        left: 15px;
    }

    .card3 {
        top: 255px;
    }

    .card4 {
        top: 255px;
        right: 125px;
    }

    .card5 {
        top: 255px;
        left: 125px;
    }

    .teamPart1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px;
    }

    .teamBox {
        display: flex;
        height: 125vh;
        flex-direction: column;
        background-color: #2D2D2D;
        align-items: center;
    }

    .aboutUsBox>.internalAboutBox>.part2 {
        display: none;
        flex-direction: column;
        justify-content: center;
    }
}

@media only screen and (max-width: 768px) {
    .homeBg {
        background-image: none;
        background-color: #1b1b1b;
        height: 100vh;
        background-size: cover;
        align-items: flex-start;
        justify-content: center;
        padding-right: 100px;
    }

    .homeLogo {
        display: none;
    }

    .internalAboutBox {
        margin-top: 0px;
        margin-bottom: 25px;
        flex-direction: row;
        justify-content: center;
    }
}

@media only screen and (max-width: 680px) {
    .MeetTeamHeader {
        font-size: xx-large !important;
    }

    .aboutUsBox>.internalAboutBox>.part1>.part1Container>.aboutText {
        padding-top: 10px;
        padding-bottom: 5px;
        font-size: large !important;
    }

    .teamBox {
        display: flex;
        height: 130vh;
        flex-direction: column;
        background-color: #2D2D2D;
        align-items: center;
    }

    .teamLogo {
        width: 86px;
        height: 75px;
    }

    .card1 {
        top: -55px;
        right: 15px;
    }

    .card2 {
        top: -55px;
        left: 50px;
    }

    .card3 {
        top: 255px;
        left: 50px;
    }

    .card4 {
        top: 255px;
        right: 15px;
    }

    .card5 {
        top: 565px;
        left: -100px;
    }

    .awardsBox {
        display: none;
    }
}

@media only screen and (max-width: 602px) {
    .MeetTeamHeader {
        font-size: xx-large !important;
    }

    .teamBox {
        display: flex;
        height: 130vh;
        flex-direction: column;
        background-color: #2D2D2D;
        align-items: center;
    }

    .teamLogo {
        width: 86px;
        height: 75px;
    }

    .card1 {
        top: -55px;
        right: -15px;
    }

    .card2 {
        top: -55px;
        left: 20px;
    }

    .card3 {
        top: 255px;
        left: 20px;
    }

    .card4 {
        top: 255px;
        right: -15px;
    }

    .card5 {
        top: 565px;
        left: -100px;
    }
}


@media only screen and (max-width: 482px) {
    .MeetTeamHeader {
        font-size: xx-large !important;
    }

    .teamBox {
        display: flex;
        height: 130vh;
        flex-direction: column;
        background-color: #2D2D2D;
        align-items: center;
    }

    .teamContainer {
        display: none;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        padding: 0px;
    }

    .teamCardBox {
        display: flex;
        flex-direction: column;
    }

    .cardContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .cardPart2 {
        display: flex;
        flex-direction: column;
    }

    .cardIcon {
        width: 108px;
        height: 108px;
        margin-right: 15px;
    }

    .card {
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 391px) {
    .aboutUsBox>.internalAboutBox>.part1>.part1Container>.headerText {
        font-size: xx-large !important;
        padding-bottom: 25px;
    }

    .aboutUsBox>.internalAboutBox>.part1 {
        padding-left: 50px;
        padding-right: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}


@media only screen and (max-width: 320px) {
    .aboutUsBox>.internalAboutBox>.part1>.part1Container>.headerText {
        font-size: xx-large !important;
        padding-bottom: 25px;
    }

    .aboutUsBox>.internalAboutBox>.part1 {
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.wrapper {
    max-width: 1335px;
    box-sizing: border-box;
    padding: 0 20px;
    margin: auto;
}

.wrapper a {
    display: inline-block;
    margin: 5px;
}

.wrapper a:first-child {
    margin-left: 0;
}

.slider-wrapper {
    position: relative;
    min-height: 75vh;
    overflow: hidden;
}

.slide {
    height: 70vh;
    background-size: cover !important;
}

.slide::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.9)));
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
    bottom: 0;
    left: 0;
}

.previousButton, .nextButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    background: url('../assets/svg/next.svg') no-repeat center center / 16px;
    width: 32px;
    height: 32px;
    text-indent: -9999px;
    cursor: pointer;
}

.previousButton:hover, .nextButton:hover {
    background: url('../assets/svg/next.svg') no-repeat center center / 16px;
}

.previousButton {
    left: 0;
    -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
    transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
    left: -10px;
}

.nextButton {
    right: 0;
}

.nextButton:hover {
    right: -10px;
}

.slider-content {
    text-align: center;
}

.slider-content .inner {
    padding: 0 70px;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.slider-content .inner button {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.slider-content .inner h1 {
    font-weight: 900;
    margin: 0 auto;
    max-width: 840px;
    color: #FFFFFF;
    font-size: 64px;
    line-height: 1;
}

.slider-content .inner p {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 1.5;
    margin: 20px auto 30px;
    max-width: 640px;
}

.slider-content section {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.slider-content section span {
    color: #FFFFFF;
}

.slider-content section span {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    display: inline-block;
    text-align: left;
    line-height: 1.4;
    vertical-align: middle;
    margin-left: 10px;
}

.slider-content section img {
    width: 40px;
    height: 40px;
    border: solid 2px rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    vertical-align: middle;
}

.slider-content section span strong {
    color: #FFFFFF;
    font-size: 14px;
    display: block;
}

@media (max-height: 500px) {
    .slider-wrapper, .slide {
        height: calc(100vh - 75px);
    }
}

@media (max-width: 640px) {
    .slider-wrapper, .slide {
        height: calc(80vh - 75px);
    }
}

@media (max-height: 600px) {
    .slider-content .inner h1 {
        font-size: 32px;
    }
}

@media (max-width: 640px) {
    .slider-content .inner h1 {
        font-size: 32px;
    }
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F6F578;
    border-radius: 10px;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: #F6F578;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #F6F578;
    background-image: -webkit-gradient(linear,
            40% 0%,
            75% 84%,
            from(#4D9C41),
            to(#19911D),
            color-stop(.6, #54DE5D))
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #aca49c;
    font-size: 0.875em;
}

input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder {
    color: #bbb5af;
}

input::-moz-placeholder, textarea::-moz-placeholder {
    color: #aca49c;
    font-size: 0.875em;
}

input:focus::-moz-placeholder, textarea:focus::-moz-placeholder {
    color: #bbb5af;
}

input::placeholder, textarea::placeholder {
    color: #aca49c;
    font-size: 0.875em;
}

input:focus::placeholder, textarea::focus:placeholder {
    color: #bbb5af;
}

input::-ms-placeholder, textarea::-ms-placeholder {
    color: #aca49c;
    font-size: 0.875em;
}

input:focus::-ms-placeholder, textarea:focus::-ms-placeholder {
    color: #bbb5af;
}

/* on hover placeholder */

input:hover::-webkit-input-placeholder, textarea:hover::-webkit-input-placeholder {
    color: #e2dedb;
    font-size: 0.875em;
}

input:hover:focus::-webkit-input-placeholder, textarea:hover:focus::-webkit-input-placeholder {
    color: #cbc6c1;
}

input:hover::-moz-placeholder, textarea:hover::-moz-placeholder {
    color: #e2dedb;
    font-size: 0.875em;
}

input:hover:focus::-moz-placeholder, textarea:hover:focus::-moz-placeholder {
    color: #cbc6c1;
}

input:hover::placeholder, textarea:hover::placeholder {
    color: #e2dedb;
    font-size: 0.875em;
}

input:hover:focus::placeholder, textarea:hover:focus::placeholder {
    color: #cbc6c1;
}

input:hover::placeholder, textarea:hover::placeholder {
    color: #e2dedb;
    font-size: 0.875em;
}

input:hover:focus::-ms-placeholder, textarea:hover::focus:-ms-placeholder {
    color: #cbc6c1;
}

.header {
    position: relative;
    margin: 100px 0 25px 0;
    font-size: 2.3em;
    text-align: center;
    letter-spacing: 7px;
}

#form {
    position: relative;
    width: 600px;
    margin: 50px auto 100px auto;
}

input {
    font-family: 'Lato', sans-serif;
    font-size: 0.875em;
    width: 470px;
    height: 50px;
    padding: 0px 15px 0px 15px;

    background: transparent;
    outline: none;
    color: #726659;

    border: solid 1px #b3aca7;
    border-bottom: none;

    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
}

input:hover {
    background: #b3aca7;
    color: #e2dedb;
}

textarea {
    width: 470px;
    max-width: 470px;
    height: 110px;
    max-height: 110px;
    padding: 15px;

    background: transparent;
    outline: none;

    color: #726659;
    font-family: 'Lato', sans-serif;
    font-size: 0.875em;

    border: solid 1px #b3aca7;

    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
}

textarea:hover {
    background: #b3aca7;
    color: #e2dedb;
}

.topBefore {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

#submit {

    padding: 0;
    margin: -5px 0px 0px 0px;

    font-family: 'Lato', sans-serif;
    font-size: 0.875em;
    color: #b3aca7;

    outline: none;
    cursor: pointer;

    border: solid 1px #b3aca7;
    border-top: none;
}

#submit:hover {
    color: #e2dedb;
}

@media only screen and (max-width: 601px) {
    #form {
        position: relative;
        width: 500px;
        margin: 50px auto 100px auto;
    }
}

@media only screen and (max-width: 512px) {
    textarea {
        width: 275px;
        max-width: 470px;
        height: 110px;
        max-height: 110px;
        padding: 15px;
        background: transparent;
        outline: none;
        color: #726659;
        font-family: 'Lato', sans-serif;
        font-size: 0.875em;
        border: solid 1px #b3aca7;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
    }

    input {
        font-family: 'Lato', sans-serif;
        font-size: 0.875em;
        width: 275px;
        height: 50px;
        padding: 0px 15px 0px 15px;

        background: transparent;
        outline: none;
        color: #726659;

        border: solid 1px #b3aca7;
        border-bottom: none;

        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
    }


    #form {
        position: relative;
        width: 375px;
        margin: 50px auto 100px auto;
    }
}

@media only screen and (max-width: 425px) {
    #form {
        position: relative;
        width: 310px;
        margin: 30px auto 60px auto;
    }
}